import { instance, instanceWithAuth } from '@/api';

// HTTP reqeust & response와 관련된 기본 설정을 해줌
const config = {
  url_commCode: '/api/commCode/',
  url_main: '/api/main/',
  url_potential: '/api/potential/',
};

const mainAxiosCall = {
  /** *********** 공통 함수 영역  ***********  */
  // 공통코드 정보 호출
  fetchCommCodeData() {
    const commCodeParam = [
      { columnId: 'USE_YY_CD' }, // 년도
      { columnId: 'ENGY_KIND_CD' }, // 에너지원
      { columnId: 'CLSF_KIND_CD' }, // 사용용도
      { columnId: 'ENGY_ALL_QTY_CD' }, // 건물소비량
      { columnId: 'MAIN_PURPS_CD' }, // 주용도
      { columnId: 'SIDO' },
      { columnId: 'SIGUNGU' },
      { columnId: 'SUGGEST_CD' }, // 건물 정보 수정 제안
      { columnId: 'INSLT_ST_CD' }, // 사용승인일
    ];
    return instanceWithAuth.post(`${config.url_commCode}getCommCodeList`, commCodeParam);
  },
  // 시도 정보 호출
  fetchSidoList() {
    return instanceWithAuth.post(`${config.url_commCode}getSidoList`);
  },
  // 시군구 정보 호출
  fetchSigunguList(sidoId) {
    return instanceWithAuth.post(`${config.url_commCode}getSigunguList`, {
      sidoId,
    });
  },

  /** *********** 지도 함수 영역  ***********  */
  // 지도 인허가 데이터 조회 ex) 스타벅스
  fetchSearchAuthData(payload) {
    return instanceWithAuth.post(`${config.url_main}getBuildDataInfo`, {
      payload,
    });
  },
  // 건물 기본 정보 데이터 조회
  fetchBuildBasicData(payload) {
    return instanceWithAuth.post(`${config.url_main}getBuildDataInfo`, {
      payload,
    });
  },
  // 건물 기본 정보 층별 개요
  fetchBuildFloorData(payload) {
    return instanceWithAuth.post(`${config.url_main}getBuildDataInfo`, {
      payload,
    });
  },
  // 지도의 영역 클릭 ex) 강남구, 역삼동..
  fetchMapAreaData(payload) {
    return instanceWithAuth.post(`${config.url_main}getBuildDataInfo`, {
      payload,
    });
  },
  // 인허가 데이터 정보
  fetchBuildLicenseData(payload) {
    return instanceWithAuth.post(`${config.url_main}getBuildDataInfo`, {
      payload,
    });
  },
  // 에너지 사용량
  fetchEnergyUseData(payload) {
    return instanceWithAuth.post(`${config.url_main}getEnergyUseData`, {
      payload,
    });
  },
  // 차트 데이터
  fetchEnergyChartData(payload) {
    return instanceWithAuth.post(`${config.url_main}getEnergyChartData`, {
      payload,
    });
  },
  // 계량기 정보
  fetchEnergyMeterInfoData(payload) {
    return instanceWithAuth.post(`${config.url_main}getBuildDataInfo`, {
      payload,
    });
  },
  // 올가미 선택한 용도별 통계 요약
  fetchBuildLassoStmtData(payload) {
    return instanceWithAuth.post(`${config.url_main}getBuildDataInfo`, {
      payload,
    });
  },
  //  올가미 건물 선택 리스트
  fetchBuildLassoChoiceData(payload) {
    return instanceWithAuth.post(`${config.url_main}getBuildDataInfo`, {
      payload,
    });
  },
  // 임시 저장된 데이터로 건물정보 로드
  fetchTempBuildInfoData(payload) {
    return instanceWithAuth.post(`${config.url_main}getBuildDataInfo`, {
      payload,
    });
  },
  fetchTotareaDstrbData(payload) {
    return instanceWithAuth.post(`${config.url_main}getBuildDataInfo`, {
      payload,
    });
  },
  fetchNewEnergyHeatData(mgmBldPk) {
    return instanceWithAuth.post(`${config.url_potential}getGeothermalEnergyPotential`, mgmBldPk);
  },
  fetchBuildMapngGradeData(payload) {
    return instanceWithAuth.post(`${config.url_main}getBuildDataInfo`, {
      payload,
    });
  },
  fetchBuildReadHistoryData(payload) {
    return instanceWithAuth.post(`${config.url_main}setBuildReadHistory`, {
      payload,
    });
  },
  fetchPdfBepaExist(payload) {
    return instance.post(`${config.url_main}pdf/fetchPdfBepaExist`, {
      payload,
    });
  },
  fetchBuildGetBookmarkData(payload) {
    return instanceWithAuth.post(`${config.url_main}getBuildBookmark`, {
      payload,
    });
  },
  fetchBuildSetBookmarkData(payload) {
    return instanceWithAuth.post(`${config.url_main}setBuildBookmark`, {
      payload,
    });
  },
  fetchBuildUseYyCd(payload) {
    return instanceWithAuth.post(`${config.url_main}getBuildUseYyCd`, { payload });
  },
  fetchBuildTotalTitleData(payload) {
    return instanceWithAuth.post(`${config.url_main}getBuildTotalTitle`, { payload });
  },
  fetchSelectedSimilarBuildingData(payload) {
    return instanceWithAuth.post(`${config.url_main}getSelectedSimilarBuildingData`, { payload });
  },
  fetchSimilarBuildingData(payload) {
    return instanceWithAuth.post(`${config.url_main}getSimilarBuildingData`, { payload });
  },
  fetchBuildKbossMapData(payload) {
    return instanceWithAuth.post(`${config.url_main}getBuildKbossMapData`, { payload });
  },
  fetchBuildKbossDelete(payload) {
    return instanceWithAuth.post(`${config.url_main}setBuildKbossDelete`, { payload });
  },
  fetchBuildGetKbossYn(payload) {
    return instanceWithAuth.post(`${config.url_main}getBuildGetKbossYn`, { payload });
  },
  fetchBuildGradeList() {
    return instanceWithAuth.get(`${config.url_main}getBuildGradeList`);
  },
  fetchFloorNo(payload) {
    return instanceWithAuth.post(`${config.url_main}getFloorNo`, { payload });
  },
  fetchYearEngyData(payload) {
    return instanceWithAuth.post(`${config.url_main}getYearEngyData`, { payload });
  },
  fetchYearTableEngyData(payload) {
    return instanceWithAuth.post(`${config.url_main}getYearTableEngyData`, { payload });
  },
  fetchBuildBasicOneData(payload) {
    return instanceWithAuth.post(`${config.url_main}getBuildBasicOneData`, { payload });
  },
  fetchBuildBasicOneListData(payload) {
    return instanceWithAuth.post(`${config.url_main}getBuildBasicOneListData`, { payload });
  },
  fetchBuildBasicTotalData(payload) {
    return instanceWithAuth.post(`${config.url_main}getBuildBasicTotalData`, { payload });
  },
  fetchBuildTenantInfoData(payload) {
    return instanceWithAuth.post(`${config.url_main}getBuildTenantInfoData`, { payload });
  },
  fetchBuildTenantEngyData(payload) {
    return instanceWithAuth.post(`${config.url_main}getBuildTenantEngyData`, { payload });
  },
  fetchBuildFlrInfoData(payload) {
    return instanceWithAuth.post(`${config.url_main}getBuildFlrInfoData`, { payload });
  },
  fetchBuildTenantInsertData(payload) {
    return instanceWithAuth.post(`${config.url_main}setBuildTenantInsertData`, { payload });
  },
  fetchBuildSavedMeterInfoData(payload) {
    return instanceWithAuth.post(`${config.url_main}getBuildSavedMeterInfoData`, { payload });
  },
  fetchMgmUpperBldPk(payload) {
    return instanceWithAuth.post(`${config.url_main}getMgmUpperBldPk`, { payload });
  },
  fetchBuildTenantEngyUseData(payload) {
    return instanceWithAuth.post(`${config.url_main}getBuildTenantEngyUseData`, { payload });
  },
  fetchTenantYearEngyData(payload) {
    return instanceWithAuth.post(`${config.url_main}getTenantYearEngyData`, { payload });
  },
  fetchBuildTenantCompareData(payload) {
    return instanceWithAuth.post(`${config.url_main}getBuildTenantCompareData`, { payload });
  },
  fetchTenantYearEngyTypeUseData(payload) {
    return instanceWithAuth.post(`${config.url_main}getTenantYearEngyTypeUseData`, { payload });
  },
  fetchTenantYearEngyTypeUse2Data(payload) {
    return instanceWithAuth.post(`${config.url_main}getTenantYearEngyTypeUse2Data`, { payload });
  },
  fetchClimateBoxData(payload) {
    return instanceWithAuth.post(`${config.url_main}getClimateBoxData`, { payload });
  }
};
// eslint-disable-next-line import/prefer-default-export
export { mainAxiosCall };
