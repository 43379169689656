const initBuildEditOfferInfo = () => ({
  mgmBldPk: '',
  suggestCd: [],
  suggestText: '',
  suggestEmail: '',
  suggestEmailYn: '',
});

export default {
  SET_COMM_CODE_DATA(state, payload) {
    state.commCodeData = payload;
  },
  // payload = {key: 키, value: 값}
  SET_INIT_SELECTED_COMM_CODE(state, payload) {
    state.selected[payload.key] = payload.value;
  },
  SET_MAP_AREA_DATA(state, payload) {
    state.mapAreaData = payload.map((v) => ({
      dongNm: v.dongnm,
      mainPurpsNm: v.mainpurpsnm,
      bldCnt: v.bldcnt,
      buildEnergyQty: v.buildenergyqty,
      buildEnergy1st: v.buildenergy1st,
      buildEnergyCo2: v.buildenergyco2,
      buildHetgQty: v.buildhetgqty,
      buildAconQty: v.buildaconqty,
      buildBaseQty: v.buildbaseqty,
      buildAveQty: v.buildaveqty,
    }));
  },
  SET_BUILD_BASIC_DATA(state, payload) {
    state.buildBasic = payload;
  },
  SET_LAYER_INFO(state, payload) {
    state.layerInfo.grndFlrCnt = payload[0].grndflrcnt;
    state.layerInfo.ugrndFlrCnt = payload[0].ugrndflrcnt;
  },
  SET_BUILD_FLOOR_DATA(state, payload) {
    state.buildFloor = payload;
  },
  SET_ENERGY_USE_YEAR_DATA(state, payload) {
    state.selected.useYyCd.cdId = payload.useYyCd;
  },
  SET_ENERGY_KIND_CHART_DATA(state, payload) {
    state.energyChartTotal = payload;

    // console.log(`로그 :${JSON.stringify(state.energyChartTotal[0].use_qty)}`)

    /* const o = {};
    // eslint-disable-next-line no-plusplus
    for (let i = 0, l = payload.length; i < l; i++) {
      const key = Object.keys(payload[i]);
      if (!o[key]) {
        o[key] = [];
      }
      o[key].push(payload[i][key]);
    } */
    // state.energyChartTotal = o;
  },
  SET_CLSF_KIND_CHART_DATA(state, payload) {
    state.energyChartTotal = payload;
  },
  // 검색 데이터 ex)스타벅스
  SET_SEARCH_AUTH_DATA(state, payload) {
    state.searchItem = payload.map((v) => ({
      bplcnm: v.bplcnm,
      bldPk: v.mgm_bld_pk,
      x: v.st_x,
      y: v.st_y,
    }));
  },
  // 시도 데이터 설정
  SET_SIDO_LIST(state, payload) {
    state.sidoList = payload;
  },
  // 시군구 데이터 설정
  SET_SIGUNGU_LIST(state, payload) {
    state.sigunguList = payload;
  },
  // 필터 선택된 데이터 설정 - 시도
  // payload = {key: 키, value: 값}
  SET_INIT_SELECTED_ADDR(state, payload) {
    state.selected[payload.key] = payload.value;
  },
  // 인허가 리스트
  SET_BUILD_LICENSE_DATA(state, payload) {
    state.licenseList = payload;
  },
  SET_ENERGY_METER_INFO_DATA(state, payload) {
    state.meterList = payload;
  },
  SET_BUILD_LASSO_STMT_DATA(state, payload) {
    state.buildLassoStmt = payload;
  },
  SET_BUILD_LASSO_CHOICE_DATA(state, payload) {
    state.buildLassoChoice = payload;
  },
  SET_ENERGY_USE_CHANGE(state, engyUseChange) {
    state.engyUseChange = engyUseChange;
  },

  SET_SELECT_PURPS_DATA(state, mainPurpsCd) {
    state.selected.MAIN_PURPS_CD = mainPurpsCd;
  },
  SET_TEMP_BUILD_INFO_DATA(state, payload) {
    state.tempBuildInfo = payload;
  },
  SET_TOTAREA_DSTRB_DATA(state, payload) {
    state.totareaDstrb = payload;
  },
  SET_NEW_ENERGY_HEAT_DATA(state, payload) {
    state.newEnergyHeat = payload;
  },
  SET_BUILD_EDIT_OFFER_RESET(state) {
    state.buildInfoEditOffer = initBuildEditOfferInfo();
  },
  SET_BUILD_MAPNG_GRADE_DATA(state, payload) {
    state.buildMapngGrade = payload;
  },
  SET_BUILD_READ_HISTORY_DATA(state, payload) {
    state.buildReadHistory = payload;
  },
  SET_BUILD_USE_YY_DATA(state, payload) {
    state.buildUseYy = payload.map((v) => ({
      cdId: v.cdid,
      cdNm: v.cdnm,
    }));
  },
  SET_SELECT_MENU_COMP(state, payload) {
    state.selectMenuComp = payload;
  },
  SET_BUILD_SEARCH_BLD_DATA(state, payload) {
    state.inputSearch = payload;
  },
  SET_KBOSS_MODAL_STATUS(state, payload) {
    state.KBossModalStatus = payload;
  },
  SET_BUILD_TOTAL_DATA(state, payload) {
    state.bldMainYn = payload;
  },
  SET_ADDRESS_DATA(state, data) {
    state.addressSearchData = data; // 응답 데이터를 state에 저장
  },
  SET_BUILD_MGM_BLD_ID(state, data) {
    state.selected.mgmBldId = data;
  },
};
