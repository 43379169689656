<template>
  <div>
    <div class="mb-2">
      <h3>
        에너지소비량
        <span class="caution">해당 정보는 법적 효력이 없으며, 참고 자료로만 활용 가능합니다</span>
      </h3>
    </div>
    <div class="eng_area">
      <b-row>
        <b-col cols="5">
          <h5><span class="info">소비량 분리 (단위환산, 1MJ=0.278kWh, 1Mcal = 1.163kWh)</span></h5>
        </b-col>
        <b-col cols="7">
          <b-row>
            <b-col cols="4">
              <b-form-checkbox v-model="mainSelected.climateCheck" class="p-0" name="climateCheck" plain @change="onClickEnergyUseInfo">
                <span class="h5">기후정규화 </span>
              </b-form-checkbox>
            </b-col>
            <b-col cols="8">
              <div v-if="climateBoxData.length > 0" class="text-left">
                <span style="margin-right: 5px">
                  <b-badge pill variant="success" style="border-radius: 5px  !important;">{{ climateBoxData[0].target_loc_nm }}</b-badge></span
                >
                <span style="margin-right: 5px"
                  ><b-badge pill variant="warning" style="border-radius: 5px  !important;">난방도일 {{ climateBoxData[0].hdd }}</b-badge></span
                >
                <span style="margin-right: 5px"
                  ><b-badge pill variant="primary" style="background-color: #4D96FF !important; color: #ffffff !important; border-radius: 5px  !important;"
                    >냉방도일 {{ climateBoxData[0].cdd }}</b-badge
                  ></span
                >
                <span style="margin-right: 5px"><img src="@/assets/images/logo/help.png" @mouseout="mout" @mouseover="mover"/></span>
              </div>
              <span v-show="climateBoxShow" class="menuHelpBox heatEnergyExp">
                보정된 냉/난방 사용랑 = 해당 기후지역 및 연도의 냉/난방 사용량 /해당 기후지역 및 연도의 냉/난방 정규화 계수(냉/난방도일)
              </span>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <div>
        <b-tabs content-class="mt-1" fill>
          <b-tab title="월별 에너지 소비량">
            <energy-month-use :mgm-bld-pk="mgmBldPk"></energy-month-use>
          </b-tab>
          <b-tab title="연도별 에너지소비량" active>
            <energy-year-use :mgm-bld-pk="mgmBldPk"></energy-year-use>
          </b-tab>
          <b-tab v-if="mgmUpperBldPk" title="총괄 표제부 월별 에너지소비량">
            <energy-month-use :mgm-bld-pk="mgmUpperBldPk"></energy-month-use>
          </b-tab>
          <b-tab v-if="mgmUpperBldPk" title="총괄 표제부 연도별 에너지소비량">
            <energy-year-use :mgm-bld-pk="mgmUpperBldPk"></energy-year-use>
          </b-tab>
        </b-tabs>
      </div>
    </div>
  </div>
</template>
<script>
import { BTableSimple, BThead, BTbody, BTr, BTh, BTd, BRow, BCol, BFormGroup, BProgress, BProgressBar, BTabs, BTab, BFormCheckbox, BBadge } from 'bootstrap-vue';
import { mapGetters } from 'vuex';

import EchartStackedArea from '@/views/building/EchartEnergy/EchartStackedArea';
import EchartBar from '@/views/building/EchartEnergy/EchartBar';
import EnergyYearUse from '@/views/building/EnergyUse/periodEngyUse/EnergyYearUse';
import vSelect from 'vue-select';
import EnergyMonthUse from '~/building/EnergyUse/periodEngyUse/EnergyMonthUse.vue';

export default {
  components: {
    EnergyMonthUse,
    BFormCheckbox,
    EnergyYearUse,
    EchartBar,

    EchartStackedArea,

    BTableSimple,
    BThead,
    BTbody,
    BTr,
    BTd,
    BTh,
    BRow,
    BCol,
    BFormGroup,
    vSelect,
    BProgress,
    BProgressBar,
    BTabs,
    BTab,
    BBadge,
  },
  props: {
    mgmBldPk: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      // 넘길 변수 설정
      clsfKind: 'clsfKind',
      engyKind: 'engyKind',
      xAxisData: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
      useYySelected: { cdNm: '2017년', cdId: '2017' },
      totareaDstrb: 'N', // Y일경우총 괄표제부의 에너지 소비량을 건물의 연면적으로 배분한 값
      isSudoQty: false,

      mgmUpperBldPk: '',
      // 해당 건물의 에너지 사용량 데이터가 있는 년도
      // buildUseYyCd: [],
      resultData: {},
      climateBoxShow: false,
      climateBoxData: [],
    };
  },
  computed: {
    ...mapGetters({
      // getEnergyUseData: 'main/getEnergyUseData',
      commCodeData: 'main/getCommCodeData',
      mainSelected: 'main/getSelectedCodeData',
      buildUseYyCd: 'main/getBuildUseYyData',
    }),
    useYyStateSelected() {
      return this.mainSelected.useYyCd.cdId;
    },
  },
  watch: {
    useYyStateSelected() {
      this.loadClimateBox();
    },
  },
  async mounted() {
    // 페이지 생성 시 데이터 불러옴
    this.useYySelected = await this.mainSelected.useYyCd;
    await this.onClickEnergyUseInfo();
    this.loadTotareaDstrb();
    await this.loadClimateBox(); // 기상청 데이터

    // 에너지 사용량만 있는 년도 불러옴
    await this.$store.dispatch('main/FETCH_BUILD_USE_YY_CD', { mgmBldPk: this.mgmBldPk });

    // 해당 건물에 총괄 표제부 건물인지 조회
    this.mgmUpperBldPk = await this.$store.dispatch('main/FETCH_MGM_UPPER_BLD_PK', { mgmBldPk: this.mgmBldPk });
  },
  methods: {
    async onClickEnergyUseInfo() {
      this.resultData = await this.$store.dispatch('main/FETCH_ENERGY_USE_DATA', {
        mgmBldPk: this.mgmBldPk,
        useYyCd: this.mainSelected.useYyCd.cdId,
        climateCheck: this.mainSelected.climateCheck, // 기후정규화
      });
      if (this.resultData[0].sudoqty !== 0) this.isSudoQty = true;
    },
    loadTotareaDstrb() {
      // * 총괄표제부의 에너지 소비량을 건물의 연면적으로 배분한 값입니다.
      this.$store
        .dispatch('main/FETCH_TOTAREA_DSTRB_DATA', {
          dataCode: 'totareaDstrb',
          mgmBldPk: this.mgmBldPk,
        })
        .then(() => {
          this.totareaDstrb = this.$store.state.main.totareaDstrb[0].fn_get_eng_div_yn;
        })
        .catch(() => {});
    },
    async loadClimateBox() {
      this.climateBoxData = await this.$store.dispatch('main/FETCH_CLIMATE_BOX_DATA', {
        mgmBldPk: this.mgmBldPk,
        useYyCd: this.mainSelected.useYyCd.cdId,
      });
    },
    mover() {
      this.climateBoxShow = true;
    },
    mout() {
      this.climateBoxShow = false;
    },
  },
};
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
<style scoped>
th,
td {
  border: 1px solid #cccccc;
  text-align: center;
}

.transform-style {
  text-transform: none;
}

.col-1 {
  width: 20%;
}
.col-2 {
  width: 15%;
}
.col-3 {
  width: 15%;
}
.col-4 {
  width: 15%;
}
.col-5 {
  width: 15%;
}
.col-6 {
  width: 20%;
}
.totareaStyle {
  font-size: 11px;
  text-align: right;
  color: blue;
}

/* 스타일을 적용할 프로그레스 바 컨테이너 */
.progress-container {
  display: inline-block;
  width: 50%;
  background-color: #ccc;
  overflow: hidden;
}

/* 프로그레스 바 */
.progress-bar {
  width: 46px;
  height: 13px;
  background-color: #4441d4;
}
.progress-text {
  display: inline-block;
  vertical-align: top;
  margin-left: 10px;
  width: 29px;
  height: 11px;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #4441d4;
}
.menuHelp {
  vertical-align: middle;
  img {
    width: 20px;
  }
}
.heatEnergyExp {
  top: 55px;
  left: 40px;
  width: 320px;
  line-height: 20px;
}
</style>
